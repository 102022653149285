/* global __MANIFEST_JSON_DATA__ */
import "normalize.css/normalize.css"
import React from "react"
import { hydrate } from "react-dom"
import { matchPath } from "react-router"

import "./index.css"

;(async () => {
  const [slug] = Object.entries(__MANIFEST_JSON_DATA__).find(([slug]) =>
    matchPath(window.location.pathname, {
      path: slug === "index" ? "/" : "/" + slug,
      exact: true,
    })
  )

  const { basename, dataPublicPath } = __MANIFEST_JSON_DATA__[slug]

  const response = await fetch(dataPublicPath)
  const data = await response.json()
  const { default: Component } = await import(
    /* webpackChunkName: "[request]" */ `./pages/${basename}`
  )

  const container = document.getElementById("container")

  hydrate(<Component data={data} />, container)
})()
